
import Layout from '@/admin/layouts/Default.vue';
import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading.vue';
import Editor from '@/components/modules/Editor.vue';
import BtnDefault from '@/components/BtnDefault.vue';

@Component({
    components: {
        Layout,
        Loading,
        Editor,
        BtnDefault,
    },
})
export default class OfferComponent extends Vue {}
